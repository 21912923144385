<template>
  <div class='home'>
    <!-- header -->
    <Header></Header>

    <div class='container'>
      <!-- 左侧导航栏 -->
      <side-bar></side-bar>

      <div class='content'>
        <div class="load-tip" v-show="isLoad === true">
          <div class="loading wrapper">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
        <!-- 地图 -->
        <iframe
          id="unity-infame"
          ref="iframeDom"
          src="https://map.dv101.com"
          frameborder="0"
          scrolling="no"
          v-show="isLoad === false"
        >
        </iframe>
        <!-- 底部 -->
        <Footer></Footer>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import SideBar from '@/components/SideBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Map',
  data: function() {
    return {
      isLoad: true
    }
  },
  components: {
    Header,
    Footer,
    SideBar
  },
  mounted() {
    var that = this
    const iframe = document.querySelector('#unity-infame')
    // 处理兼容行问题
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', function () {
        that.isLoad = false
      })
    } else {
      iframe.onload = function () {
        // iframe加载完毕以后执行操作
        that.isLoad = false
      }
    }
  },
  methods: {

  }
}
</script>
